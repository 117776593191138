import PropTypes from 'prop-types';
import { Types } from 'trhub-utils';

const isDateFormat = function (props, propName, componentName) {
  const regex = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;

  if (!regex.test(props[propName])) {
    return new Error(
      `Invalid prop ${propName} passed to ${componentName}. Expected a valid date format yyyy-mm-dd.`,
    );
  }
};

export const betTypesPropType = PropTypes.arrayOf(
  PropTypes.oneOf(Types.betTypes),
);

export const typesPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
).isRequired;

export const userPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  email: PropTypes.string,
  name: PropTypes.string,
  companyName: PropTypes.string,
  mobile: PropTypes.string,
  customerNumber: PropTypes.number,
  personalIdentityNumber: PropTypes.string,
  organizationNumber: PropTypes.string,
  collectionPayee: PropTypes.bool,
  address: PropTypes.shape({
    address: PropTypes.string,
    careOf: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
  }),
  roles: PropTypes.arrayOf(PropTypes.string),
});

export const productType = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  sku: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  vat: PropTypes.oneOf(Types.VATs).isRequired,
  site: PropTypes.string.isRequired,
  allowedPaymentMethods: PropTypes.arrayOf(PropTypes.string).isRequired,
  paymentInterval: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  betType: betTypesPropType,
  costCenter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  project: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const productsType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
  }),
);

export const productPropType = PropTypes.shape({
  ...productType,
  products: productsType,
});

export const subscriptionPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  campaign: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  payee: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  paymentMethod: PropTypes.string,
  startDate: PropTypes.string.isRequired,
  dueDate: PropTypes.string,
  unsubscribeDate: PropTypes.string,
  endDate: PropTypes.string,
  invoiceId: PropTypes.string,
  pauseStartDate: PropTypes.string,
  pauseEndDate: PropTypes.string,
  unsubscribeReason: PropTypes.shape({
    reason: PropTypes.string,
    text: PropTypes.string,
  }),
  latestRenewalAttempt: PropTypes.string,
  latestSuccessfulRenewal: PropTypes.string,
  drupalId: PropTypes.number,
  eventLog: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      timestamp: PropTypes.string.isRequired,
      order: PropTypes.string,
      user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    }),
  ),
});

export const issuePropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  dispatcher: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  severity: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  created: PropTypes.string.isRequired,
  assignee: PropTypes.string,
});

export const lineItemPropType = PropTypes.shape({
  product: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
  }),
  price: PropTypes.number,
  vat: PropTypes.number,
  quantity: PropTypes.number,
});

export const campaignType = {
  id: PropTypes.string,
  name: PropTypes.string,
  code: PropTypes.string,
  type: PropTypes.string,
  price: PropTypes.number,
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const campaignPropType = PropTypes.shape({
  ...campaignType,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
      percentage: PropTypes.number.isRequired,
    }),
  ),
});

export const orderPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  orderStatus: PropTypes.oneOf(Object.keys(Types.orderStatus)),
  paymentStatus: PropTypes.oneOf(Object.keys(Types.paymentStatus)),
  created: isDateFormat,
  netPrice: PropTypes.number,
  totalVAT: PropTypes.number,
  totalPrice: PropTypes.number,
  lineItems: PropTypes.arrayOf(lineItemPropType),
  user: userPropType,
});

export const editionType = PropTypes.shape({
  id: PropTypes.string,
  sku: PropTypes.string.isRequired,
  editionLabel: PropTypes.string.isRequired,
  productionDate: PropTypes.string,
  lastOrderDate: PropTypes.string,
  distributionDate: PropTypes.string,
  deliveryDate: PropTypes.string.isRequired,
});

export const termsType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  version: PropTypes.string,
  text: PropTypes.string,
  status: PropTypes.oneOf(Object.keys(Types.termsStatus)),
  updated: PropTypes.string,
  published: PropTypes.string,
});
