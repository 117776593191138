import { useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  MenuItem,
  TextField as TextFieldCore,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { format } from 'date-fns';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { Types } from 'trhub-utils';
import * as Yup from 'yup';

import DateInputField from '~/components/DateInputField';
import ErrorMessage from '~/components/ErrorMessage';
import { NumberField } from '~/components/NumberField';
import { campaignPropType } from '~/propTypes';
import * as productsQuery from '~/utils/_GetAllProducts.gql';
import toOptions from '~/utils/toOptions';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Ett namn måste anges.'),
  price: Yup.number()
    .required('Ett belopp måste anges.')
    .moreThan(-1, 'Beloppet måste vara noll eller positivt heltal.'),
  type: Yup.string()
    .required('En rabattyp måste anges.')
    .when('products', {
      is: value => value.length > 1,
      then: Yup.string()
        .required('Rabattypen måste vara av typ Prisavdrag eller Fast pris.')
        .oneOf(
          ['monthlyDiscount', 'fixedPrice'],
          'Rabattypen måste vara av typ Prisavdrag eller Fast pris.',
        ),
      value: Yup.number()
        .required('Ett belopp måste anges.')
        .moreThan(-1, 'Beloppet måste vara noll eller ett positivt heltal.'),
    }),
  site: Yup.string().required('En sajt måste anges.'),
  expireDate: Yup.date()
    .min(format(new Date(), 'yyyy-MM-dd'), 'Ogiltigt datum.')
    .nullable(),

  limit: Yup.number()
    .notRequired()
    .positive('Begränsat antal användare måste anges som ett positivt heltal.'),
});

Yup.addMethod(Yup.array, 'atMostOneSubscription', function (errorMessage) {
  return this.test('atMostOne', errorMessage, function (value) {
    const { path, createError } = this;
    return (
      value.filter(product => product.type === 'subscription').length < 2 ||
      createError({ path, message: errorMessage })
    );
  });
});

const additionalValidation = Yup.object().shape({
  products: Yup.array()
    .atMostOneSubscription('En kampanj får max innehålla en prenumeration.')
    .min(1, 'Minst en produkt eller produktgrupp måste anges.')
    .required('Minst en produkt eller produktgrupp måste anges.')
    .of(
      Yup.object().shape({
        percentage: Yup.number()
          .required('Procentuell inkomstfördelning måste anges.')
          .moreThan(-1, 'Procentuell inkomstfördelning får ej vara negativt.')
          .lessThan(101, 'Procentuell inkomstfördelning får ej överstiga 100.'),
      }),
    ),

  duration: Yup.number().when('products', {
    is: value => value.some(product => product.type === 'subscription'),
    then: Yup.number()
      .moreThan(0, 'Kampanjlängden måste vara ett positivt heltal.')
      .required('Kampanjlängd måste anges.'),
  }),
});

export default function CampaignForm({
  initialValues,
  addCampaign = false,
  onSubmit,
  totalPercentError,
}) {
  const [productSearch, setProductSearch] = useState('');
  const refetchProduct = useCallback(search => {
    setProductSearch(search);
    //refetch();
  }, []);

  const { loading, error, data } = useQuery(productsQuery, {
    variables: { filter: { search: productSearch } },
  });

  if (error) {
    return <ErrorMessage error={error} />;
  }

  const productList = data?.getAllProducts || [];

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={
        addCampaign
          ? // eslint-disable-next-line unicorn/prefer-spread
            validationSchema.concat(additionalValidation)
          : validationSchema
      }
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ submitForm, isSubmitting, values, errors, setFieldValue }) => (
        <Form
          css={({ theme }) => ({
            '> * + *': {
              marginTop: theme.spacing(2),
            },
            '> *': {
              width: '100%',
            },
            '> .MuiFormControl-root': {
              backgroundColor: theme.palette.background.default,
            },
          })}
        >
          <Field
            component={TextField}
            name="name"
            label="Namn"
            variant="outlined"
          />
          <Field
            component={TextField}
            name="code"
            label="Rabattkod"
            variant="outlined"
          />
          <NumberField
            name="limit"
            value={values.limit || ''}
            label="Begränsat antal användare"
          />
          <Field
            component={TextField}
            select
            name="site"
            label="Sajt"
            variant="outlined"
          >
            {toOptions(Types.sites).map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </Field>
          <DateInputField
            setFieldValue={setFieldValue}
            name="expireDate"
            value={values.expireDate}
            InputProps={{
              inputProps: { min: format(new Date(), 'yyyy-MM-dd') },
            }}
            label="Sista dag för nyttja kampanjen"
            clearIconEnabled
            variant="outlined"
          />
          <Field
            label="Rabattyp"
            component={TextField}
            select
            name="type"
            variant="outlined"
          >
            {Object.entries(Types.campaignTypes).map(([value, label]) => (
              <MenuItem key={label} value={value}>
                {label}
              </MenuItem>
            ))}
          </Field>
          {addCampaign && (
            <>
              <Field
                multiple
                noOptionsText={
                  loading ? 'Laddar in produkter...' : 'Inga träffar.'
                }
                name="products"
                component={Autocomplete}
                filterSelectedOptions
                options={
                  values.type === 'monthlyDiscount'
                    ? productList.filter(
                        product => product.type === 'subscription',
                      )
                    : productList
                }
                getOptionLabel={option => option.name}
                onChange={(_, value) => {
                  setFieldValue(
                    'products',
                    value.map(val => ({
                      ...val,
                      percentage: 100 / value.length,
                    })),
                  );
                }}
                onInputChange={async (_, value) => {
                  refetchProduct(value);
                }}
                filterOptions={createFilterOptions({
                  limit: 150,
                })}
                renderInput={params => (
                  <TextFieldCore
                    {...params}
                    label="Produkter"
                    variant="outlined"
                    error={errors.products != null}
                  />
                )}
              />
              {errors.products != null && (
                <Box mb={2}>
                  <Alert severity="error">{errors.products}</Alert>
                </Box>
              )}
              {values.products.map(product => {
                const index = values.products.findIndex(
                  item => item.id === product.id,
                );

                return (
                  <Field
                    key={product.id}
                    component={TextField}
                    name={`products[${index}].percentage`}
                    label={`Inkomstfördelning för ${product.name}`}
                    type="number"
                    variant="outlined"
                  />
                );
              })}
              {totalPercentError !== '' && (
                <Box>
                  <Alert severity="error">{totalPercentError}</Alert>
                </Box>
              )}
            </>
          )}
          {values.type && (
            <NumberField
              name="price"
              label={
                values.type === 'fixedPrice'
                  ? 'Pris att betala direkt'
                  : 'Pris att betala per månad'
              }
              value={values.price}
            />
          )}

          {values.products.some(product => product.type === 'subscription') && (
            <>
              <NumberField
                name="duration"
                value={values.duration || ''}
                label="Kampanjlängd i månader"
              />

              {values.type === 'fixedPrice' && (
                <FormControlLabel
                  label="Avsluta kopplad prenumeration efter att kampanjlängden har passerat"
                  control={
                    <Checkbox
                      checked={values.endSubscriptionAfterDuration}
                      onChange={_ =>
                        setFieldValue(
                          'endSubscriptionAfterDuration',
                          !values.endSubscriptionAfterDuration,
                        )
                      }
                    />
                  }
                />
              )}
            </>
          )}
          {isSubmitting && <LinearProgress />}
          <Box>
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
            >
              Spara
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

CampaignForm.propTypes = {
  initialValues: campaignPropType,
  totalPercentError: PropTypes.string,
  addCampaign: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};
